// cookie
let cmp_data = localStorage.getItem('tnlmediagene-cmp')
if (cmp_data !== null) {
    document.getElementById("privacy-wrap").remove()
} else {
    document.getElementById("privacy-wrap").style.display = 'block'
    document.getElementById("privacy-submit").addEventListener("click", granteCMP)
}

function granteCMP() {
    const consentMode = {
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
        'functionality_storage': 'granted',
        'personalization_storage': 'granted',
        'security_storage': 'granted',
        'wait_for_update': 500
    }
    gtag('consent', 'update', consentMode)
    gtag('event', 'User-Consent', {
        'action': 'trigger',
        'label': 'update'  
    });
    document.getElementById("privacy-wrap").remove()
    localStorage.setItem("tnlmediagene-cmp", JSON.stringify(consentMode));
}